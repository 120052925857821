/** @define Button */

:root {
  --Button-border-color: currentcolor;
  --Button-border-width: 1px;
  --Button-color: inherit;
  --Button-disabled-opacity: 0.6;
  --Button-font: inherit;
  --Button-padding: 0.4em 0.75em;
}

/**
 * The button classes are best applied to links and buttons.
 * These components can be used in forms, as calls to action, or as part of the
 * general UI of the site/app.
 */

/**
 * 1. Normalize `box-sizing` across all elements that this component could be
 *    applied to.
 * 2. Inherit text color from ancestor.
 * 3. Inherit font styles from ancestor.
 * 4. Prevent button text from being selectable.
 */

.Button {
  background: transparent;
  border-color: var(--Button-border-color);
  border-radius: 0;
  border-style: solid;
  border-width: var(--Button-border-width);
  box-sizing: border-box; /* 1 */
  color: var(--Button-color); /* 2 */
  display: inline-block;
  font: var(--Button-font); /* 3 */
  margin: 0;
  padding: var(--Button-padding);
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none; /* 4 */
}

/**
 * Remove excess padding and border in Firefox 4+
 */

.Button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * Work around a Firefox/IE bug where the transparent `button` background
 * results in a loss of the default `button` focus styles.
 */

.Button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

/**
 * UI states
 */

.Button:hover,
.Button:focus,
.Button:active {
  text-decoration: none;
}

.Button:disabled,
.Button.is-disabled {
  cursor: default;
  opacity: var(--Button-disabled-opacity);
}
