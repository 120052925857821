/* @define utilities */

.u-fillViewport {
  width: 100vw !important;
  height: 100vh !important;
}

.u-fillViewportH {
  width: 100vw !important;
}

.u-fillViewportV {
  height: 100vh !important;
}

.u-sizeCover {
  flex-basis: 100% !important;
}

.u-sizeFullV {
  height: 100% !important;
}

.u-scroll {
  overflow: scroll !important;
}
