@import "../base";

/** @define Icon */
.Icon {
  height: 1em;
  width: 1em;
}

.Icon--large {
  width: calc(80rem / var(--base-font-size));
  height: calc(80rem / var(--base-font-size));
}

.Icon--center {
  display: block;
  margin: 0 auto;
}

.Icon--loader {
  width: 35px;
  height: 35px;
  margin: 0 auto;
  position: relative;
  background: #fff;
  border: 3px solid;
  border-color: var(--color-sky) #fff #fff;
  border-radius: 100%;
  opacity: 0;
  animation:
    Icon--appear 500ms 500ms ease-out forwards,
    Icon--spinning 1600ms linear infinite;
}

@keyframes Icon--appear {
  to {
    opacity: 1;
  }
}

@keyframes Icon--spinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
