@import "suitcss-components-button";
@import "../base";

/** @define Button */
:root {
  --Button-border-width: 0;
  --Button-border-color: var(--color-sky);
  --Button-background-color: var(--color-sky);
  --Button-color: #fff;
  --Button-font: inherit;
  --Button-padding: 1em;
  --Button-disabled-opacity: 0.6;
}

.Button {
  cursor: default;
  padding: var(--Button-padding);
  color: var(--Button-color);
  font-style: normal;
  font-weight: var(--font-weight-bold);
  background-color: var(--Button-background-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/**
 * 1. Use default pointer for linked buttons
 */

.Button:link {
  cursor: pointer; /* 1 */
}

.Button:hover,
.Button:focus,
.Button:active {
  text-decoration: none;
}

.Button:focus {
  outline: none;
  box-shadow: 0 0 5px var(--Button-background-color);
}

.Button--link {
  padding: 0;
  cursor: pointer;
  font-style: italic;
  color: var(--Button-background-color);
  font-weight: var(--font-weight-normal);
  background-color: transparent;
  text-align: left;
}

.Button--link:hover,
.Button--link:focus,
.Button--link:active {
  text-decoration: underline;
}

.Button--secondary {
  background-color: var(--color-pale);
  border-color: var(--color-pale);
}

.Button--inverse {
  background-color: var(--Button-color);
  border-color: var(--Button-color);
  color: var(--Button-background-color);
}

.Button--warning {
  background-color: var(--color-tomato);
  border-color: var(--color-tomato);
}

.Button--round {
  padding: calc(var(--Button-padding) / 2) var(--Button-padding);
  border-radius: calc(1em + var(--Button-padding));
}
