@import "../base";

/** @define PageHead */
.PageHead {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem var(--viewport-padding);
  border-bottom: 1px solid var(--color-light);

  position: relative;
  z-index: 1;
}

.PageHead::before {
  content: "";
  width: 100%;
  height: 100%;

  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;

  background-color: #fff;
}

@media (--md-viewport), (--lg-viewport) {
  .PageHead {
    font-size: calc(18rem / var(--base-font-size));
  }
}

.PageHead-title {
  display: flex;
  align-items: center;
  margin-right: auto;

  font-style: italic;
  white-space: nowrap;
  font-size: calc(27rem / var(--base-font-size));
  color: var(--color-pale);
}

.PageHead-title:hover,
.PageHead-title:focus {
  text-decoration: none;
}

.PageHead-logo {
  display: block;
  height: 23px;
  margin-right: var(--space-s);
}

@media (--sm-viewport), (--md-viewport), (--lg-viewport) {
  .PageHead-logo {
    height: 31px;
  }
}

.PageHead-navigation {
  display: flex;
}

.PageHead-item {
  display: inline;
  list-style: none;
}

.PageHead-link,
.PageHead-link:visited {
  display: inline-block;
  padding: 0.45em;
  margin: 0 0.3em;
  border: 0;
  background-color: transparent;
}

.PageHead-button:link,
.PageHead-button:link:hover,
.PageHead-button:link:focus {
  display: inline-block;
  padding: 0.5em 1em;
  border: 1px solid var(--color-sky);
  border-radius: 2em;
  font-style: normal;
  font-size: calc(14rem / var(--base-font-size));
  font-weight: var(--font-weight-bold);
  text-decoration: none;
}

.PageHead-menu {
  width: 100%;
  padding-top: 20px;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: -2;
  background: #fff;
  transform: translateY(-100%);
  transition: transform 250ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

@media (--md-viewport), (--lg-viewport) {
  .PageHead-menu {
    width: auto;
  }
}

.PageHead-trigger {
  margin-right: 0;
}

.PageHead-trigger--large {
  display: none;
}

@media (--md-viewport), (--lg-viewport) {
  .PageHead-trigger--large {
    display: block;
  }

  .PageHead-trigger--small {
    display: none;
  }
}

.PageHead-menu.is-open,
.PageHead-menu:target {
  transform: translateY(calc(0% - 20px));
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.PageHead-menu.is-open ~ .PageHead-trigger,
.PageHead-menu:target ~ .PageHead-trigger {
  display: none;
}

.PageHead-untrigger:link,
.PageHead-untrigger:link:focus,
.PageHead-untrigger:link:hover {
  display: none;
  margin-right: 0;
}

.PageHead-untrigger::before {
  content: attr(data-title-small);
}

@media (--md-viewport), (--lg-viewport) {
  .PageHead-untrigger::before {
    content: attr(data-title-large);
  }
}

.PageHead-menu.is-open ~ .PageHead-untrigger,
.PageHead-menu:target ~ .PageHead-untrigger {
  display: block;
}
