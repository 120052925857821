/* ==========================================================================
   Spacing-specific utilities
   ========================================================================== */

/**
 * Used to adjust the default spacing between components
 * Use with great care!
 *
 * u-<type><direction><size>
 *
 * A = all
 * T = top
 * R = right
 * B = bottom
 * L = left
 * H = horizontal
 * V = vertical
 *
 * n = none
 * s = small
 * m = medium
 * l = large
 */

:root {
	--space-n: 0;
	--space-b: .125rem;
	--space-s: .25rem;
	--space-m: .5rem;
	--space-l: 1rem;
	--space-g: 1.5rem;
}

/* All */

.u-paddingAn { padding: var(--space-n) !important; }
.u-paddingAb { padding: var(--space-b) !important; }
.u-paddingAs { padding: var(--space-s) !important; }
.u-paddingAm { padding: var(--space-m) !important; }
.u-paddingAl { padding: var(--space-l) !important; }
.u-paddingAg { padding: var(--space-g) !important; }

.u-marginAn { margin: var(--space-n) !important; }
.u-marginAb { margin: var(--space-b) !important; }
.u-marginAs { margin: var(--space-s) !important; }
.u-marginAm { margin: var(--space-m) !important; }
.u-marginAl { margin: var(--space-l) !important; }
.u-marginAg { margin: var(--space-g) !important; }

/* Vertical */

.u-paddingVn { padding-top: var(--space-n) !important; padding-bottom: var(--space-n) !important; }
.u-paddingVb { padding-top: var(--space-b) !important; padding-bottom: var(--space-b) !important; }
.u-paddingVs { padding-top: var(--space-s) !important; padding-bottom: var(--space-s) !important; }
.u-paddingVm { padding-top: var(--space-m) !important; padding-bottom: var(--space-m) !important; }
.u-paddingVl { padding-top: var(--space-l) !important; padding-bottom: var(--space-l) !important; }
.u-paddingVg { padding-top: var(--space-g) !important; padding-bottom: var(--space-g) !important; }

.u-marginVn { margin-top: var(--space-n) !important; margin-bottom: var(--space-n) !important; }
.u-marginVb { margin-top: var(--space-b) !important; margin-bottom: var(--space-b) !important; }
.u-marginVs { margin-top: var(--space-s) !important; margin-bottom: var(--space-s) !important; }
.u-marginVm { margin-top: var(--space-m) !important; margin-bottom: var(--space-m) !important; }
.u-marginVl { margin-top: var(--space-l) !important; margin-bottom: var(--space-l) !important; }
.u-marginVg { margin-top: var(--space-g) !important; margin-bottom: var(--space-g) !important; }

/* Horizontal */

.u-paddingHn { padding-left: var(--space-n) !important; padding-right: var(--space-n) !important; }
.u-paddingHb { padding-left: var(--space-b) !important; padding-right: var(--space-b) !important; }
.u-paddingHs { padding-left: var(--space-s) !important; padding-right: var(--space-s) !important; }
.u-paddingHm { padding-left: var(--space-m) !important; padding-right: var(--space-m) !important; }
.u-paddingHl { padding-left: var(--space-l) !important; padding-right: var(--space-l) !important; }
.u-paddingHg { padding-left: var(--space-g) !important; padding-right: var(--space-g) !important; }

.u-marginHn { margin-left: var(--space-n) !important; margin-right: var(--space-n) !important; }
.u-marginHb { margin-left: var(--space-b) !important; margin-right: var(--space-b) !important; }
.u-marginHs { margin-left: var(--space-s) !important; margin-right: var(--space-s) !important; }
.u-marginHm { margin-left: var(--space-m) !important; margin-right: var(--space-m) !important; }
.u-marginHl { margin-left: var(--space-l) !important; margin-right: var(--space-l) !important; }
.u-marginHg { margin-left: var(--space-g) !important; margin-right: var(--space-g) !important; }

/* Top */

.u-paddingTn { padding-top: var(--space-n) !important; }
.u-paddingTb { padding-top: var(--space-b) !important; }
.u-paddingTs { padding-top: var(--space-s) !important; }
.u-paddingTm { padding-top: var(--space-m) !important; }
.u-paddingTl { padding-top: var(--space-l) !important; }
.u-paddingTg { padding-top: var(--space-g) !important; }

.u-marginTn { margin-top: var(--space-n) !important; }
.u-marginTb { margin-top: var(--space-b) !important; }
.u-marginTs { margin-top: var(--space-s) !important; }
.u-marginTm { margin-top: var(--space-m) !important; }
.u-marginTl { margin-top: var(--space-l) !important; }
.u-marginTg { margin-top: var(--space-g) !important; }

/* Bottom */

.u-paddingBn { padding-bottom: var(--space-n) !important; }
.u-paddingBb { padding-bottom: var(--space-b) !important; }
.u-paddingBs { padding-bottom: var(--space-s) !important; }
.u-paddingBm { padding-bottom: var(--space-m) !important; }
.u-paddingBl { padding-bottom: var(--space-l) !important; }
.u-paddingBg { padding-bottom: var(--space-g) !important; }

.u-marginBn { margin-bottom: var(--space-n) !important; }
.u-marginBb { margin-bottom: var(--space-b) !important; }
.u-marginBs { margin-bottom: var(--space-s) !important; }
.u-marginBm { margin-bottom: var(--space-m) !important; }
.u-marginBl { margin-bottom: var(--space-l) !important; }
.u-marginBg { margin-bottom: var(--space-g) !important; }

/* Left */

.u-paddingLn { padding-left: var(--space-n) !important; }
.u-paddingLb { padding-left: var(--space-b) !important; }
.u-paddingLs { padding-left: var(--space-s) !important; }
.u-paddingLm { padding-left: var(--space-m) !important; }
.u-paddingLl { padding-left: var(--space-l) !important; }
.u-paddingLg { padding-left: var(--space-g) !important; }

.u-marginLn { margin-left: var(--space-n) !important; }
.u-marginLb { margin-left: var(--space-b) !important; }
.u-marginLs { margin-left: var(--space-s) !important; }
.u-marginLm { margin-left: var(--space-m) !important; }
.u-marginLl { margin-left: var(--space-l) !important; }
.u-marginLg { margin-left: var(--space-g) !important; }

/* Right */

.u-paddingRn { padding-right: var(--space-n) !important; }
.u-paddingRb { padding-right: var(--space-b) !important; }
.u-paddingRs { padding-right: var(--space-s) !important; }
.u-paddingRm { padding-right: var(--space-m) !important; }
.u-paddingRl { padding-right: var(--space-l) !important; }
.u-paddingRg { padding-right: var(--space-g) !important; }

.u-marginRn { margin-right: var(--space-n) !important; }
.u-marginRb { margin-right: var(--space-b) !important; }
.u-marginRs { margin-right: var(--space-s) !important; }
.u-marginRm { margin-right: var(--space-m) !important; }
.u-marginRl { margin-right: var(--space-l) !important; }
.u-marginRg { margin-right: var(--space-g) !important; }

/* negative */

.u-marginBgN {
  margin-bottom: calc(0 - var(--space-g)) !important;
}

.u-marginBlN {
  margin-bottom: calc(0 - var(--space-l)) !important;
}

.u-marginBmN {
  margin-bottom: calc(0 - var(--space-m)) !important;
}

.u-marginBsN {
  margin-bottom: calc(0 - var(--space-s)) !important;
}

.u-marginBbN {
  margin-bottom: calc(0 - var(--space-b)) !important;
}

.u-marginTgN {
  margin-top: calc(0 - var(--space-g)) !important;
}

.u-marginTlN {
  margin-top: calc(0 - var(--space-l)) !important;
}

.u-marginTmN {
  margin-top: calc(0 - var(--space-m)) !important;
}

.u-marginTsN {
  margin-top: calc(0 - var(--space-s)) !important;
}

.u-marginTbN {
  margin-top: calc(0 - var(--space-b)) !important;
}

.u-marginVgN {
  margin-top: calc(0 - var(--space-g)) !important;
  margin-bottom: calc(0 - var(--space-g)) !important;
}

.u-marginVlN {
  margin-top: calc(0 - var(--space-l)) !important;
  margin-bottom: calc(0 - var(--space-l)) !important;
}

.u-marginVmN {
  margin-top: calc(0 - var(--space-m)) !important;
  margin-bottom: calc(0 - var(--space-m)) !important;
}

.u-marginVsN {
  margin-top: calc(0 - var(--space-s)) !important;
  margin-bottom: calc(0 - var(--space-s)) !important;
}

.u-marginVbN {
  margin-top: calc(0 - var(--space-b)) !important;
  margin-bottom: calc(0 - var(--space-b)) !important;
}

.u-marginHgN {
  margin-left: calc(0 - var(--space-g)) !important;
  margin-right: calc(0 - var(--space-g)) !important;
}

.u-marginHlN {
  margin-left: calc(0 - var(--space-l)) !important;
  margin-right: calc(0 - var(--space-l)) !important;
}

.u-marginHmN {
  margin-left: calc(0 - var(--space-m)) !important;
  margin-right: calc(0 - var(--space-m)) !important;
}

.u-marginHsN {
  margin-left: calc(0 - var(--space-s)) !important;
  margin-right: calc(0 - var(--space-s)) !important;
}

.u-marginHbN {
  margin-left: calc(0 - var(--space-b)) !important;
  margin-right: calc(0 - var(--space-b)) !important;
}

.u-marginLgN {
  margin-left: calc(0 - var(--space-g)) !important;
}

.u-marginLlN {
  margin-left: calc(0 - var(--space-l)) !important;
}

.u-marginLmN {
  margin-left: calc(0 - var(--space-m)) !important;
}

.u-marginLsN {
  margin-left: calc(0 - var(--space-s)) !important;
}

.u-marginLbN {
  margin-left: calc(0 - var(--space-b)) !important;
}

.u-marginRgN {
  margin-right: calc(0 - var(--space-g)) !important;
}

.u-marginRlN {
  margin-right: calc(0 - var(--space-l)) !important;
}

.u-marginRmN {
  margin-right: calc(0 - var(--space-m)) !important;
}

.u-marginRsN {
  margin-right: calc(0 - var(--space-s)) !important;
}

.u-marginRbN {
  margin-right: calc(0 - var(--space-b)) !important;
}
