/* @define Map */

.Map {
  flex: 1 0 auto;
  position: relative;
}

.Map-container {
  width: 100%;
  height: 100%;

  position: absolute;
  left: 0;
  top: 0;
}

.Map-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.Map-recruit {
  display: none;
  justify-content: space-between;
  align-items: baseline;
  height: 0;
  width: calc(100% - var(--viewport-padding) * 2);
  margin: var(--viewport-padding);
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
}

:root.has-js .Map-recruit {
  position: absolute;
  top: 0;
  left: 0;
}

.Map-panel {
  background: #fff;
  padding: 1em;
  border: 1px solid var(--color-light);
}

.Map-controls {
  display: none;
  width: 100%;
  padding: var(--viewport-padding) calc(var(--viewport-padding) / 2);
  position: absolute;
  bottom: 0;
  left: 0;
}

:root.has-js .Map-controls {
  display: flex;
}

:root.has-js .Map-controls > * {
  display: block;
  margin: 0 calc(var(--viewport-padding) / 2);
}

@media (--md-viewport), (--lg-viewport) {
  :root.has-js .Map-controls {
    display: none;
  }

  .Map-recruit {
    display: flex;
  }
}

.Map-locate {
  position: absolute;
  top: var(--viewport-padding);
  right: var(--viewport-padding);
}

:root:not(.has-js) .Map-locate {
  display: none;
}

.Map-position {
  padding: 0.5em 1em;

  position: relative;

  color: #fff;
  line-height: 1rem;
  font-family: var(--font-family);
  font-weight: var(--font-weight-bold);
  font-size: calc(14rem / var(--base-font-size));
  border-radius: 2em;
  background-color: var(--color-sky);
  transform: translate(-50%, calc(-100% - 7px));
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Map-position::after {
  content: "";
  width: 0;
  height: 0;
  border: 7px solid;
  border-color: var(--color-sky) transparent transparent;

  position: absolute;
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
}

.Map-popup {
  padding: var(--space-s);
  font-size: calc(14rem / var(--base-font-size));
  line-height: calc(22 / 14);
  font-family: var(--font-family);
  background-color: rgba(255, 255, 255, 0.94);
  border-radius: 3px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.085);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Map-popup--flat {
  padding: 0;
  box-shadow: none;
  border-radius: 0;
}

.Map-coopProps {
  margin: var(--space-b) calc((var(--space-b) / 2) * -1) 0;
  padding-bottom: 1.5em;
  overflow: hidden;
  position: relative;
}

.Map-coopProp {
  float: left;
  padding: 0 calc(var(--space-b) / 2);
}

.Map-propsSum {
  display: block;
  width: 100%;

  position: absolute;
  left: 0;
  bottom: 0;

  text-align: center;
  color: var(--color-unknown);
}

.Map-coopProp:hover::after {
  content: attr(data-title);
  display: block;
  width: 100%;
  padding: 0 calc(var(--space-b) / 2);

  position: absolute;
  left: 0;
  bottom: 0;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  color: var(--color-text);
}

.Map-coopProp:hover ~ .Map-propsSum {
  display: none;
}

.mapboxgl-popup-content {
  padding: 0;
  background: transparent;
  box-shadow: none;
  border-radius: 0;
}

.mapboxgl-ctrl-top-left,
.mapboxgl-ctrl-top-right,
.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-right {
  z-index: 0;
}

.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border-top-color: rgba(255, 255, 255, 0.94);
}

.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
  border-bottom-color: rgba(255, 255, 255, 0.94);
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: rgba(255, 255, 255, 0.94);
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: rgba(255, 255, 255, 0.94);
}
