/* @define Onboarding */

.Onboarding {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}

.Onboarding.is-loading {
  justify-content: center;
  align-items: center;
}

.Onboarding-reel {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  overflow: auto;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}

.Onboarding-reel::-webkit-scrollbar {
  display: none;
}

.Onboarding-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 100%;
  padding: var(--space-m) var(--space-m) 0;

  background: #fff;
}

.Onboarding-container {
  width: 100%;
  margin: var(--space-m) var(--space-m) var(--space-l);
}

.Onboarding-image {
  display: block;
  max-width: 100%;
  height: auto;
}

.Onboarding-pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: var(--space-s) 0;
}

.Onboarding-page {
  display: block;
  width: 8px;
  height: 8px;
  margin: 0 0.25em;
  position: relative;
  overflow: hidden;
  color: transparent;
}

.Onboarding-page::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  border: 2px solid var(--color-pale);

  position: absolute;
  left: 0;
  top: 0;

  background-color: #fff;
  border-radius: 100%;
  transition:
    border-color 100ms ease-out,
    border-width 200ms ease-out;
}

.Onboarding-page:hover::after {
  border-color: var(--color-sky);
}

:root.has-js .Onboarding-page.is-active::after,
.Onboarding-page:target::after {
  border-width: 4px;
  border-color: var(--color-sky);
}
