@import "../base";

/** @define Comment */
.Comment {
  margin: var(--viewport-padding) 0;
  font-weight: var(--font-weight-light);
  font-size: calc(14rem / var(--base-font-size));
  line-height: calc(20rem / var(--base-font-size));
}

.Comment-date {
  float: right;
  padding-left: 1em;
  color: var(--color-dim);
  font-weight: var(--font-weight-bold);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Comment--slim {
  margin: calc(var(--viewport-padding) / 2) 0;
}

.Comment-author {
  font-weight: var(--font-weight-bold);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
