@import "../base";

/** @define Menu */
.Menu {
  width: 100%;
  min-width: 12em;
  border-bottom: 1px solid var(--color-light);

  background-color: #fff;
}

.Menu-link:link,
.Menu-link:visited {
  display: block;
  padding: var(--space-s);
}

.Menu-item:last-child .Menu-link:link {
  padding-bottom: var(--space-s);
}

.Menu-item:first-child .Menu-link:link {
  padding-top: var(--space-s);
}
