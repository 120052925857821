/* @define utilities */

@media (--sm-viewport) {
  .u-sm-hidden {
    display: none !important;
  }
}

@media (--md-viewport) {
  .u-md-hidden {
    display: none !important;
  }
}

@media (--lg-viewport) {
  .u-lg-hidden {
    display: none !important;
  }
}

/**
 * Show/Hide element depending on JavaScript being availible
 */

:root.has-js .u-hiddenHasScript {
  display: none !important;
}

:root:not(.has-js) .u-hiddenNoScript {
  display: none !important;
}
